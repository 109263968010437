/* eslint-disable no-nested-ternary */
import KYCStatus from 'src/shared/config/KYCStatus';
import UserType from 'src/shared/config/UserType';
import moment from 'moment';
import CountryCodesData from 'src/shared/config/CountryCodes.json';
import { i18n } from 'next-i18next';

const isBeta = () => {
    if (process.env.BETA === undefined) {
        return false;
    }
    return process.env.BETA === 'true';
};

const isCheckedRememberMe = (state) => !!(state.user && state.user.memberInfo && state.user.memberInfo.id);

const processMemberInfo = (state) => (isCheckedRememberMe(state) ? state.user : state.tempUser) || {};

const getAsPath = (pathname, query = {}) => {
    let as = pathname;
    Object.keys(query).forEach((key) => {
        if (as.includes(`[${key}]`)) {
            as = as.replace(`[${key}]`, encodeURIComponent(query[key]));
        } else if (!as.includes('?')) {
            as = `${as}?${key}=${query[key]}`;
        } else {
            as = `${as}&${key}=${query[key]}`;
        }
    });
    return { path: { pathname, query }, as };
};

const getNextKYCStatus = (status) => {
    const statusFlow = Object.values(KYCStatus);
    const current = statusFlow.findIndex((item) => item === status);
    if (current > -1) {
        const next = current + 1 < statusFlow.length ? current + 1 : current;
        return statusFlow[next];
    }
    return statusFlow[0];
};

const convertLoginDataFormat = (resp, type) => {
    if (!(resp && resp.basicInfo && resp.jwt)) return false;
    const { basicInfo, jwt } = resp;

    switch (type) {
        case UserType.INVESTOR:
            return {
                id: basicInfo.id,
                firstName: basicInfo.firstName,
                lastName: basicInfo.lastName,
                email: basicInfo.email,
                phoneCountryCode: basicInfo.phoneCountryCode,
                phoneNumber: basicInfo.phoneNumber,
                profileImageUrl: basicInfo.profileImageUrl,
                accountType: basicInfo.accountType,
                status: basicInfo.status,
                isInitialized: basicInfo.isInitialized,
                isQuestionnaireSubmitted: basicInfo.isQuestionnaireSubmitted,
                token: jwt,
            };
        case UserType.ADVISOR:
            return {
                id: basicInfo.id,
                firstName: basicInfo.firstName,
                lastName: basicInfo.lastName,
                email: basicInfo.email,
                phoneCountryCode: basicInfo.phoneCountryCode,
                phoneNumber: basicInfo.phoneNumber,
                profileImageUrl: basicInfo.profileImageUrl,
                accountType: basicInfo.accountType,
                status: basicInfo.status,
                isInitialized: basicInfo.isInitialized,
                isQuestionnaireSubmitted: basicInfo.isQuestionnaireSubmitted,
                advisor: {
                    organization: basicInfo.advisor ? basicInfo.advisor.organization : '',
                    title: basicInfo.advisor ? basicInfo.advisor.title : '',
                },
                token: jwt,
            };
        default:
            return false;
    }
};

const abbreviateNumber = (labelValue) =>
    // Nine Zeroes for Billions
    Math.abs(Number(labelValue)) >= 1.0e9
        ? Math.abs(Number(labelValue)) / 1.0e9 + 'bn'
        : // Six Zeroes for Millions
        Math.abs(Number(labelValue)) >= 1.0e6
        ? Math.abs(Number(labelValue)) / 1.0e6 + 'm'
        : // Three Zeroes for Thousands
        Math.abs(Number(labelValue)) >= 1.0e3
        ? Math.abs(Number(labelValue)) / 1.0e3 + 'k'
        : Math.abs(Number(labelValue));
const addThousandSeparators = (value = '') => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

// Supported format: separator/abbreviate
const toMoneyFormat = (value, currency = '', format = 'abbreviate') => {
    if (!value && typeof value !== 'number') return null;

    let valueDisplay = value;
    const currencyDisplay = currency && String(currency).length ? ` ${String(currency).toUpperCase()}` : '';

    if (format === 'abbreviate') {
        valueDisplay = abbreviateNumber(value);
    }
    if (format === 'separator') {
        valueDisplay = addThousandSeparators(value);
    }

    return `$${valueDisplay}${currencyDisplay}`;
};

const getRouteUserId = (router, memberInfo = {}) => {
    if (memberInfo.id === router.query.userId) {
        return '_me';
    }
    return router.query.userId;
};

const getBaseUrl = () => `${window.location.origin}/${i18n.language}`;

// for object only
const removeEmpty = (obj) =>
    Object.keys(obj)
        .filter((key) => !!obj[key] || typeof obj[key] === 'number' || typeof obj[key] === 'boolean')
        .reduce(
            (newObj, key) =>
                typeof obj[key] === 'object' && !Array.isArray(obj[key])
                    ? { ...newObj, [key]: removeEmpty(obj[key]) }
                    : { ...newObj, [key]: obj[key] },
            {}
        );

// @TODO should be move to key related utils
const kycAPIToFormData = (data, fieldsSection) => {
    const { generalFields, fileFields, overallFields, ubosFields, boardMemberFields, bankInfoFields, amlRiskFields } = fieldsSection;
    const formData = [];
    const generalSection = {};
    const fileSection = {};
    const boardMemberSection = {};
    const bankInfoSection = {};
    const obuSection = {};
    const amlRiskSection = {};
    const overAllSection = {};

    formData.push({ rnc: data.rnc ? data.rnc.data : null }); // hot fix

    if (generalFields && Array.isArray(generalFields) && generalFields.length > 0) {
        generalFields.forEach((field) => {
            const fieldData = data[field] ? data[field] : { data: '', status: '', comment: '', aiVerification: '' };
            Object.assign(generalSection, {
                [field]: fieldData.data,
                [`${field}_status`]: fieldData.status,
                [`${field}_comment`]: fieldData.comment,
                [`${field}_aiVerification`]: fieldData.aiVerification,
            });
        });
        formData.push(generalSection);
    }

    if (fileFields && Array.isArray(fileFields) && fileFields.length > 0) {
        fileFields.forEach((field) => {
            if (data[field]) {
                Object.assign(fileSection, {
                    [field]: data[field].data.map((fileData) => ({
                        file: { path: fileData.name },
                        fileId: fileData.fileId,
                        path: fileData.url,
                        updatedAt: data[field].updatedAt,
                    })),
                    [`${field}_status`]: data[field].status,
                    [`${field}_comment`]: data[field].comment,
                    [`${field}_aiVerification`]: data[field].aiVerification,
                });
            }
        });
        formData.push(fileSection);
    }

    if (boardMemberFields) {
        Object.keys(boardMemberFields).forEach((matrix) => {
            Object.assign(boardMemberSection, {
                [matrix]: data[matrix].data.map((matrixData) => {
                    const matrixFormObj = {};
                    Object.keys(matrixData).forEach((field) => {
                        if (boardMemberFields[matrix].generalIds.includes(field)) {
                            Object.assign(matrixFormObj, {
                                [field]: matrixData[field].data,
                                [`${field}_status`]: matrixData[field].status,
                                [`${field}_comment`]: matrixData[field].comment,
                                [`${field}_aiVerification`]: matrixData[field].aiVerification,
                            });
                        } else {
                            Object.assign(matrixFormObj, { [field]: matrixData[field] });
                        }
                    });
                    return matrixFormObj;
                }),
            });
        });
        formData.push(boardMemberSection);
    }

    if (bankInfoFields && Array.isArray(bankInfoFields) && bankInfoFields.length > 0) {
        bankInfoFields.forEach((field) => {
            Object.assign(bankInfoSection, {
                [field]: data[field].data,
                [`${field}_status`]: data[field].status,
                [`${field}_comment`]: data[field].comment,
                [`${field}_aiVerification`]: data[field].aiVerification,
            });
        });
        formData.push(bankInfoSection);
    }

    if (ubosFields) {
        Object.keys(ubosFields).forEach((matrix) => {
            Object.assign(obuSection, {
                [matrix]: data[matrix].data.map((matrixData) => {
                    const matrixFormObj = {};
                    Object.keys(matrixData).forEach((field) => {
                        if (ubosFields[matrix].generalIds.includes(field)) {
                            Object.assign(matrixFormObj, {
                                [field]: matrixData[field].data,
                                [`${field}_status`]: matrixData[field].status,
                                [`${field}_comment`]: matrixData[field].comment,
                                [`${field}_aiVerification`]: matrixData[field].aiVerification,
                            });
                        } else if (ubosFields[matrix].fileIds.includes(field)) {
                            Object.assign(matrixFormObj, {
                                [field]: matrixData[field].data.map((fileData) => ({
                                    file: { path: fileData.name },
                                    fileId: fileData.fileId,
                                    path: fileData.url,
                                    updatedAt: matrixData[field].updatedAt,
                                })),
                                [`${field}_status`]: matrixData[field].status,
                                [`${field}_comment`]: matrixData[field].comment,
                                [`${field}_aiVerification`]: matrixData[field].aiVerification,
                            });
                        } else {
                            Object.assign(matrixFormObj, { [field]: matrixData[field] });
                        }
                    });
                    return matrixFormObj;
                }),
            });
        });
        formData.push(obuSection);
    }

    if (amlRiskFields && amlRiskFields.overallId && data['amlRisk']) {
        Object.assign(amlRiskSection, {
            [amlRiskFields.overallId]: data['amlRisk'][amlRiskFields.overallId],
        });
        if (Array.isArray(amlRiskFields.generalIds) && amlRiskFields.generalIds.length > 0) {
            amlRiskFields.generalIds.forEach((field) => {
                Object.assign(amlRiskSection, {
                    [field]: data['amlRisk'][field],
                    [`${field}_rank`]: data['amlRisk'][field].rank,
                    [`${field}_reason`]: data['amlRisk'][field].reason,
                    [`${field}_references`]: data['amlRisk'][field].reference,
                    [`${field}_comment`]: data['amlRisk'][field].comment,
                });
            });
        }
    }
    formData.push(amlRiskSection);

    if (overallFields && Array.isArray(overallFields) && overallFields.length > 0) {
        overallFields.forEach((field) => {
            Object.assign(overAllSection, {
                [field]: data[field],
            });
        });
        formData.push(overAllSection);
    }
    return { data, formData };
};

const kycFormDataToAPI = (formData, fieldsSection, originalStatus = null) => {
    const { generalFields, fileFields, overallFields, ubosFields, boardMemberFields, bankInfoFields, amlRiskFields, entityName } = fieldsSection;
    const jsonObj = {};
    if (overallFields) {
        overallFields.forEach((fieldId) => {
            Object.assign(jsonObj, {
                [fieldId]: formData[fieldId],
            });
        });
    }
    // define action by overall kyc status
    switch (formData.status) {
        case 'approved': {
            Object.assign(jsonObj, { action: 'approve' });
            break;
        }
        case 'changes_required': {
            Object.assign(jsonObj, { action: 'rtc' });
            break;
        }
        case 'rejected': {
            Object.assign(jsonObj, { action: 'reject' });
            break;
        }
        case 'reviewing': {
            if (originalStatus === 'reviewing') {
                Object.assign(jsonObj, { action: 'save' });
            } else if (originalStatus === 'approved') {
                Object.assign(jsonObj, { action: 'revert' });
            } else {
                Object.assign(jsonObj, { action: 'submit' });
            }
            break;
        }
        default: {
            Object.assign(jsonObj, { action: 'save' });
            break;
        }
    }
    // replace `status` by `action`
    delete jsonObj.status;

    // Convert general info to req object
    if (generalFields && Array.isArray(generalFields) && generalFields.length > 0) {
        generalFields.forEach((fieldId) => {
            Object.assign(jsonObj, {
                [fieldId]: {
                    data: formData[fieldId],
                    status: formData[`${fieldId}_status`],
                    comment: formData[`${fieldId}_comment`],
                },
            });
        });
    }

    // Convert file object to req object
    if (fileFields && Array.isArray(fileFields) && fileFields.length > 0) {
        fileFields.forEach((fieldId) => {
            Object.assign(jsonObj, {
                [fieldId]: {
                    data: Array.isArray(formData[fieldId])
                        ? formData[fieldId].map((file) => ({
                              fileId: file.fileId,
                              name: file.file.path,
                          }))
                        : [],
                    status: formData[`${fieldId}_status`],
                    comment: formData[`${fieldId}_comment`],
                },
            });
        });
    }

    if (bankInfoFields && Array.isArray(bankInfoFields) && bankInfoFields.length > 0) {
        bankInfoFields.forEach((fieldId) => {
            Object.assign(jsonObj, {
                [fieldId]: {
                    data: formData[fieldId],
                    status: formData[`${fieldId}_status`],
                    comment: formData[`${fieldId}_comment`],
                },
            });
        });
    }

    // Convert matrix object to req object
    if (ubosFields) {
        // looping for matrix
        Object.keys(ubosFields).forEach((matrixId) => {
            // looping for one type of matrix, eg: `ubos`
            const matrixObject = { data: [] };
            formData[matrixId].forEach((matrix, matrixIndex) => {
                // looping for each ubo, formData.ubo -> ..., matrix = { uboId:..., }
                matrixObject.data.push({});
                Object.keys(matrix).forEach((matrixField) => {
                    // keys of a ubo, matrixFeild = `uboId`, `name`, ...
                    // assign { name:{data:..., status: ..., comment:...}} to ubos:{data:[ matrixObject1, matrixObject2]}
                    if (ubosFields[matrixId].generalIds.includes(matrixField)) {
                        // if field = [name, address...]
                        Object.assign(matrixObject.data[matrixIndex], {
                            [matrixField]: {
                                data: matrix[matrixField],
                                comment: matrix[`${matrixField}_comment`],
                                status: matrix[`${matrixField}_status`],
                            },
                        });
                    } else if (ubosFields[matrixId].fileIds.includes(matrixField)) {
                        // if field = [addressProof, idPhotos...]
                        Object.assign(matrixObject.data[matrixIndex], {
                            [matrixField]: {
                                data: Array.isArray(matrix[matrixField])
                                    ? matrix[matrixField].map((file) => ({
                                          fileId: file.fileId,
                                          name: file.file.path,
                                      }))
                                    : [],
                                comment: matrix[`${matrixField}_comment`],
                                status: matrix[`${matrixField}_status`],
                            },
                        });
                    } else if (ubosFields[matrixId].overallIds.includes(matrixField)) {
                        // field = [uboId, status, comment...]
                        Object.assign(matrixObject.data[matrixIndex], { [matrixField]: matrix[matrixField] });
                    }
                });
            });
            Object.assign(jsonObj, { [matrixId]: matrixObject });
        });
    }

    if (boardMemberFields) {
        Object.keys(boardMemberFields).forEach((matrixId) => {
            const matrixObject = { data: [] };
            formData[matrixId].forEach((matrix, matrixIndex) => {
                matrixObject.data.push({});
                Object.keys(matrix).forEach((matrixField) => {
                    if (boardMemberFields[matrixId].generalIds.includes(matrixField)) {
                        Object.assign(matrixObject.data[matrixIndex], {
                            [matrixField]: {
                                data: matrix[matrixField],
                                comment: matrix[`${matrixField}_comment`],
                                status: matrix[`${matrixField}_status`],
                            },
                        });
                    } else if (boardMemberFields[matrixId].overallIds.includes(matrixField)) {
                        Object.assign(matrixObject.data[matrixIndex], { [matrixField]: matrix[matrixField] });
                    }
                });
            });
            Object.assign(jsonObj, { [matrixId]: matrixObject });
        });
    }

    if (amlRiskFields) {
        const amlRiskObject = {};
        Object.assign(amlRiskObject, {
            amlRisk: {
                rank: formData['rank'],
            },
        });
        amlRiskFields.amlRisk.generalIds.forEach((item) => {
            Object.assign(amlRiskObject['amlRisk'], {
                [item]: {
                    rank: formData[`${item}_rank`],
                    reason: formData[`${item}_reason`],
                    comment: formData[`${item}_comment`],
                },
            });
        });

        Object.assign(jsonObj, { ...amlRiskObject });
    }

    if (entityName) {
        jsonObj.name = { data: entityName };
    }

    return jsonObj;
};

const setMomentOffset = (serverTime) => {
    const offset = new Date(serverTime).getTime() - Date.now();
    if (Math.abs(offset) > 50) {
        moment.now = () => offset + Date.now();
    }
};

const getHoneypot = (time = null) => btoa(`ALTIVE:${time ? moment(time).valueOf() : moment.now()}`);

const timeSpanToDate = (code) => moment(new Date(code)).format('YYYY-MM-DD');

const documentToFileObject = (item) => ({
    file: { path: item.name },
    fileId: item.fileId,
    path: item.url,
});

const checkPasswordPolicy = (string) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[`~!@#$%^&*()_+,./\\;'<>?:"{}|])[A-Za-z\d`~!@#$%^&*()_+,./\\;'<>?:"{}|]{8,}$/;
    return regex.test(string);
};

const getCountryCodeList = () => {
    const options = [];
    const keyCode = {};
    CountryCodesData.forEach((element) => {
        const code = element.dial_code.replace('+', '');
        if (keyCode[code] === undefined) {
            options.push({
                label: code,
                value: code,
            });
        }
        keyCode[code] = true;
    });

    return options.sort((a, b) => parseInt(a.label, 10) - parseInt(b.label, 10));
};

const checkFormIsInvalid = (errObj) => JSON.stringify(errObj).includes(`"invalid":true`);

const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

const convertValidateObj = (errObj) =>
    errObj.map((x) => {
        const item = {};
        Object.keys(x).forEach((key) => {
            item[key] = x[key].message;
        });
        return item;
    });
const formatDateToString = (time, format = 'DD MMM YYYY') => {
    const date = moment(time);
    return date.format(format);
};

const isClient = () => typeof window !== 'undefined';

const toFileValues = (files) => {
    if (Array.isArray(files)) {
        return files.map((x) => ({
            file: {
                name: x.filename,
                path: x.url,
            },
            filename: x.filename,
            url: x.url,
        }));
    }
    if (files) {
        return [
            {
                file: {
                    name: files.filename,
                    path: files.url,
                },
                filename: files.filename,
                url: files.url,
            },
        ];
    }
    return [];
};

const copyToClipboard = (text) => {
    const textField = document.createElement('textarea');
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
};

const common = {
    isBeta,
    isCheckedRememberMe,
    processMemberInfo,
    getAsPath,
    getNextKYCStatus,
    convertLoginDataFormat,
    getRouteUserId,
    abbreviateNumber,
    addThousandSeparators,
    toMoneyFormat,
    removeEmpty,
    getBaseUrl,
    kycAPIToFormData,
    kycFormDataToAPI,
    setMomentOffset,
    getHoneypot,
    timeSpanToDate,
    documentToFileObject,
    checkPasswordPolicy,
    getCountryCodeList,
    checkFormIsInvalid,
    capitalizeFirstLetter,
    convertValidateObj,
    formatDateToString,
    isClient,
    toFileValues,
    copyToClipboard,
};

export default common;

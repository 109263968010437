import React from 'react';
import styles from './styles.module.scss';
import globalActions from 'src/shared/store/global/actions';
import { useDispatch } from 'react-redux';

interface PlainButtonProps {
    className?: string;
    onClick: () => void;
    children: React.ReactNode;
    isDisabled?: boolean;
    onConfirm?: boolean | { message: string };
    clear?: boolean;
    supportResponsive?: boolean;
}

const PlainButton = (props: PlainButtonProps) => {
    const { className, children, onClick, clear, onConfirm, supportResponsive, isDisabled } = props;
    const dispatch = useDispatch();
    const message = onConfirm['message'] ?? null;
    const onClickConfirm = () => {
        dispatch(
            globalActions.showConfirmDialog({
                text: message ?? 'Are you sure to take this action?',
                onConfirm: onClick,
            })
        );
    };
    return (
        <button
            className={`${className} ${styles.button} ${clear ? styles.clear : ''} ${supportResponsive ? styles.responsive : ''}`}
            type="button"
            onClick={onConfirm ? onClickConfirm : onClick}
            disabled={isDisabled}
        >
            {children}
        </button>
    );
};

PlainButton.defaultProps = {
    className: '',
    clear: false,
    supportResponsive: false,
    onConfirm: false,
    isDisabled: false,
};

export default PlainButton;

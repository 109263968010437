import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import globalActions from 'src/shared/store/global/actions';
import DialogModal from '../DialogModal';
import { CustomDialogType } from 'src/shared/store/global/types';
import { RootState } from 'src/store/rootReducer';

const CustomDialog = () => {
    const dialog: CustomDialogType = useSelector((state: RootState) => state.global.customDialog);
    const dispatch = useDispatch();
    const [visible, setVisible] = useState(false);

    const onClose = () => {
        setVisible(false);
        setTimeout(() => dispatch(globalActions.dismissCustomDialog()), 500);
    };

    useEffect(() => {
        setVisible(dialog.showDialog);
    }, [dialog.showDialog]);

    const childrenWithProps = React.Children.map(dialog.children, (child) => {
        if (React.isValidElement(child)) {
            return React.cloneElement<any>(child, { onClose });
        }
        return child;
    });

    return (
        <DialogModal width={dialog.width} height={dialog.height} className={dialog.className} visible={visible} onClose={onClose}>
            {childrenWithProps}
        </DialogModal>
    );
};

export default CustomDialog;

import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import DialogModal from 'src/shared/components/DialogModal';
import { useDispatch, useSelector } from 'react-redux';
import RoundedButton from 'src/shared/components/RoundedButton';
import Image from 'src/shared/components/Image';
import { useTranslation } from 'next-i18next';
import globalActions from 'src/shared/store/global/actions';
import { RootState } from 'src/store/rootReducer';

const ErrorDialog: React.FC = () => {
    const dialog = useSelector((state: RootState) => state.global.errorDialog);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [visible, setVisible] = useState(false);

    const onClose = () => {
        setVisible(false);
        setTimeout(() => dispatch(globalActions.dismissErrorDialog()), 500);
    };

    const onClickAction = () => {
        onClose();
    };

    useEffect(() => {
        setVisible(dialog.showDialog);
    }, [dialog.showDialog]);

    return (
        <DialogModal visible={visible} onClose={onClose}>
            <div className={styles.container}>
                <div className={styles.mainGroup}>
                    {dialog.icon && <Image className={styles.icon} width="22px" height="22px" src={dialog.icon} />}
                    <div className={styles.body}>
                        {dialog.title && (
                            <div className={styles.title}>{dialog.type ? t(dialog.title) + ` (${t(dialog.type)})` : t(dialog.title)}</div>
                        )}
                        {dialog.message && <div className={styles.content}>{dialog.message}</div>}
                    </div>
                </div>
                <div className={styles.buttonGroup}>
                    <RoundedButton minWidth="100px" type="black" onClick={() => onClickAction()}>
                        {t('button_ok')}
                    </RoundedButton>
                </div>
            </div>
        </DialogModal>
    );
};

export default ErrorDialog;

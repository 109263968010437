const SET_USER_INFO = 'User/SET_USER_INFO';
const SET_TEMP_USER_INFO = 'User/SET_TEMP_USER_INFO';
const CLEAR_USER_INFO = 'User/CLEAR_USER_INFO';
const CLEAR_TEMP_USER_INFO = 'User/CLEAR_TEMP_USER_INFO';
const FETCHING_USER_INFO = 'User/FETCHING_USER_INFO';
const UPDATE_USER_EXPIRE_DATE = 'User/UPDATE_USER_EXPIRE_DATE';
const UPDATE_TEMP_USER_EXPIRE_DATE = 'User/UPDATE_TEMP_USER_EXPIRE_DATE';

export default {
    SET_USER_INFO,
    SET_TEMP_USER_INFO,
    CLEAR_USER_INFO,
    CLEAR_TEMP_USER_INFO,
    FETCHING_USER_INFO,
    UPDATE_USER_EXPIRE_DATE,
    UPDATE_TEMP_USER_EXPIRE_DATE,
};

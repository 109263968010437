import React, { useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import BaseText from 'src/shared/components/BaseText';
import RoundedButton from 'src/shared/components/RoundedButton';
import useFreezingScrolling from 'src/shared/hooks/useFreezingScrolling';
import ModalBackground from 'src/shared/components/ModalBackground';
import styles from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { globalActions } from 'src/shared/store/global';
import { animated, useSpring, config } from 'react-spring';
import { preferenceActions } from 'src/store/preference';
import { RootState } from 'src/store/rootReducer';
import { fireGA } from 'src/utils/gaUtils';

const DisclaimerModal = () => {
    const { visible } = useSelector((state: RootState) => state.global.disclaimerModal);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    useFreezingScrolling(visible);

    const { y } = useSpring({ y: visible ? 0 : 70, config: config.gentle });

    const confirmDisclaimer = () => {
        dispatch(preferenceActions.setConfirmDisclaimer(true));
        dispatch(globalActions.dismissDisclaimerModal());
        fireGA('marketingQ421', 'clickDisclaimerAgreeButton', '');
    };

    useEffect(() => {
        if (visible) {
            fireGA('marketingQ421', 'disclaimerIsShown', '');
        }
    }, [visible]);

    return (
        <ModalBackground visible={visible} backgroundColor="dark">
            <animated.div className={styles.container} style={{ transform: y.to((v) => `translateY(${v}%)`) }}>
                <div className={styles.textArea}>
                    <BaseText color="white" className={styles.welcomeMsg}>
                        {t('disclaimer_review')}
                    </BaseText>
                    <BaseText color="white" className={styles.description} html={t('disclaimer:content')} />
                    <div className={styles.confirmText}>{t('disclaimer_confirm_text')}</div>
                </div>

                <div className={styles.footer}>
                    <RoundedButton className={styles.continue} type="primary-on-light" onClick={confirmDisclaimer} supportResponsive>
                        {t('continue')}
                    </RoundedButton>
                </div>
            </animated.div>
        </ModalBackground>
    );
};

export default DisclaimerModal;

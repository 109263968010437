import { useEffect, useRef } from 'react';

function useFreezingScrolling(enableFreezing: boolean) {
    const pageYOffset = useRef<number>(0);

    useEffect(() => {
        if (enableFreezing) {
            pageYOffset.current = window.pageYOffset || 0;
            document.body.style.top = `-${pageYOffset}px`;
            document.body.classList.add('fixed');
        } else {
            document.body.style.top = '0px';
            document.body.classList.remove('fixed');
            window.scrollTo(0, pageYOffset.current);
        }
        return () => {
            if (enableFreezing) {
                document.body.style.top = '0px';
                document.body.classList.remove('fixed');
                window.scrollTo(0, pageYOffset.current);
            }
        };
    }, [enableFreezing]);
}

export default useFreezingScrolling;

import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import DialogModal from 'src/shared/components/DialogModal';
import { useDispatch, useSelector } from 'react-redux';
import RoundedButton from 'src/shared/components/RoundedButton';
import Image from 'src/shared/components/Image';
import { useTranslation } from 'next-i18next';
import globalActions from 'src/shared/store/global/actions';
import useInterval from 'src/shared/hooks/useInterval';
import { RootState } from 'src/store/rootReducer';

const ExpireDialog: React.FC = () => {
    const dialog = useSelector((state: RootState) => state.global.expireDialog);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [visible, setVisible] = useState(false);
    const [timeout, setTimeout] = useState(60000);

    const onClose = () => {
        if (dialog.onConfirm) {
            dialog.onConfirm();
        }
        dispatch(globalActions.dismissExpireDialog());
    };

    useInterval(
        () => {
            if (timeout <= 1000) {
                setTimeout(0);
                dispatch(globalActions.dismissExpireDialog());
            } else {
                setTimeout(timeout - 1000);
            }
        },
        dialog.showDialog ? 1000 : null
    );

    useEffect(() => {
        setVisible(dialog.showDialog);
        if (!dialog.showDialog) {
            setTimeout(60000);
        }
    }, [dialog.showDialog]);

    return (
        <DialogModal visible={visible} onClose={onClose}>
            <div className={styles.container}>
                <div className={styles.mainGroup}>
                    {dialog.icon && <Image className={styles.icon} width="22px" height="22px" src={dialog.icon} />}
                    <div className={styles.body}>
                        <div className={styles.title}>{`${t('idle_timeout_warning').replace('@', `${timeout / 1000}`)}`}</div>
                    </div>
                </div>
                <div className={styles.buttonGroup}>
                    <RoundedButton minWidth="100px" type="black" onClick={onClose}>
                        {dialog.confirmText || t('button_ok')}
                    </RoundedButton>
                </div>
            </div>
        </DialogModal>
    );
};

export default ExpireDialog;

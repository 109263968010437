import 'src/theme/global.scss';
import 'moment/locale/en-au';
import 'moment/locale/zh-cn';
import 'moment/locale/zh-hk';
import moment from 'moment';

import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { appWithTranslation } from 'next-i18next';
import App from 'next/app';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Hydrate } from 'react-query/hydration';
import { ReactQueryDevtools } from 'react-query/devtools';
import NProgress from 'nprogress';
import AOS from 'aos';
import jsHttpCookie from 'cookie';
import Cookies from 'js-cookie';
import requestIp from 'request-ip';
import IPinfoWrapper from 'node-ipinfo';
import smoothscroll from 'smoothscroll-polyfill';

import { store, persistor } from 'src/store';
import GaService from 'src/services/GaService';
import common from 'src/shared/utils/common';
import localeUtils from 'src/shared/utils/localeUtils';
import Modal from 'src/shared/components/Modal';
import DisclaimerModal from 'src/components/DisclaimerModal';
import ConfirmDialog from 'src/shared/components/ConfirmDialog';
import AlertDialog from 'src/shared/components/AlertDialog';
import ErrorDialog from 'src/shared/components/ErrorDialog';
import ExpireDialog from 'src/shared/components/ExpireDialog';
import CustomDialog from 'src/shared/components/CustomDialog';
import ScrollToTop from 'src/shared/components/ScrollToTop';
import GaCategory from 'src/config/GaCategory';
import GaAction from 'src/config/GaAction';

const REGION_REDIRECT_TIMEOUT = process.env.REGION_CHECKING_EXPIRE_MINUTES * 60 * 1000;

const AUTO_REDIRECT_REGIONS = [
    {
        countryCode: 'AU',
        locale: 'au',
    },
];

const LOCAL_ENV_IPS = ['127.0.0.1', '::1', '::ffff:127.0.0.1'];

const ipinfo = new IPinfoWrapper(process.env.IPINFO_TOKEN);

const setCookies = (props, key) => {
    if (!props[key]) {
        return;
    }
    Cookies.set(key, props[key]);
};

const clientRegionTimeouts = {};

const AltiveApp = (props) => {
    setCookies(props, 'regionTimeout');
    setCookies(props, 'ip');

    const router = useRouter();

    const [queryClient] = useState(
        () =>
            new QueryClient({
                defaultOptions: {
                    queries: {
                        refetchOnWindowFocus: false,
                        retry: false,
                    },
                },
            })
    );

    useEffect(() => {
        NProgress.configure({ showSpinner: true });
        router.events.on('routeChangeStart', () => {
            NProgress.start();
        });

        router.events.on('routeChangeComplete', () => {
            document.body.classList.remove('fixed');
            NProgress.done();
        });
        router.events.on('routeChangeError', () => NProgress.done());
        GaService.initialize();
        AOS.init();
        smoothscroll.polyfill();
        moment.locale(localeUtils.getLocaleForServer(router.locale));

        // GA call event every 30s
        window.addEventListener('load', () => {
            setInterval(() => {
                GaService.callEvent(GaCategory.marketing, GaAction.stayed30s);
            }, 30000);
        });

        // Configure reCAPTCHA
        window.recaptchaOptions = {
            useRecaptchaNet: true,
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { Component, pageProps, err } = props;

    const renderContent = () => {
        return (
            <>
                <ScrollToTop />
                <Component {...pageProps} err={err} />
                <ConfirmDialog />
                <AlertDialog />
                <ErrorDialog />
                <ExpireDialog />
                <CustomDialog />
                <DisclaimerModal />
                <Modal />
            </>
        );
    };

    return (
        <Provider store={store}>
            <QueryClientProvider client={queryClient}>
                <Hydrate state={pageProps.dehydratedState}>
                    {common.isClient() && <PersistGate persistor={persistor}>{renderContent()}</PersistGate>}
                    {!common.isClient() && renderContent()}
                </Hydrate>
                <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
            </QueryClientProvider>
        </Provider>
    );
};

AltiveApp.getInitialProps = async (appContext) => {
    const appProps = await App.getInitialProps(appContext);
    const {
        ctx: { req, res, pathname, query },
        router,
    } = appContext;

    const data = {};

    if (!req) {
        return { ...appProps };
    }

    /*
     * Handle region redirection
     * For some countries, we have to redirect the site to special locale. To prevent from over checking, and over redirecting,
     * a timeout is set. Checking will only be done if the req IP didn't access the site for a period of time.
     * Timeout record will be stored in both client side cookies and server side cache object. The region checking will be marked
     * as timeout only if both stored records are outdated.
     * Server side records are used to indicate the shouldRedirect status if the server session keep alive during two access.
     * Cookies records are used to indicate the shouldRedirect status if the server side session is refreshed (i.e. server side cache is gone).
     * However, to reduce the memory used of server, server side records will only be recorded/updated if the site is about to be redirected.
     * Cookies records are more accurate records as it will be updated for every accesses.
     */
    const now = new Date().getTime();
    const nextRegionTimeout = now + REGION_REDIRECT_TIMEOUT;
    const detectedIP = requestIp.getClientIp(req);
    const cookies = req.headers.cookie;
    const { regionTimeout, ip } = cookies ? jsHttpCookie.parse(cookies) : {};
    const isTimedOutInCookies = !regionTimeout || +regionTimeout < now;
    const isTimedOutInServerCache = !clientRegionTimeouts[detectedIP] || clientRegionTimeouts[detectedIP] < now;
    const isTimedOut = !regionTimeout || (isTimedOutInCookies && isTimedOutInServerCache);
    let targetLocale = null;
    let shouldRedirect = false;

    /*
     * regionTimeout is used to prevent the client from keeping redirect to target locale.
     * Which means that user can change the website locale by language switch or direct access from URL before timed out.
     * Each access/redirection(router.push/router.replace) will refresh the timeout to keep UX smooth.
     * Should check if we have to redirect to target locale only when the last access was timed out or the request IP was changed.
     */
    if (isTimedOut || detectedIP !== ip) {
        // Here provide a path for testing the redirection behavior on local env (eg. http://local-dev:11111/en?reqCountryCode=AU)
        const isLocalEnv = LOCAL_ENV_IPS.includes(detectedIP);
        const reqCountryCode = isLocalEnv ? query.reqCountryCode : await ipinfo.lookupIp(detectedIP).then((response) => response.countryCode);
        const targetRegion = AUTO_REDIRECT_REGIONS[AUTO_REDIRECT_REGIONS.findIndex((item) => item.countryCode === reqCountryCode)];

        /*
         * Should redirect to target locale only if the req country is from one of the special countries
         * and req locale(i.e. locale from URL) is NOT the same as target locale(i.e. locale of country code from req IP)
         */
        if (targetRegion && router.locale !== targetRegion.locale) {
            shouldRedirect = res.writable;
            targetLocale = targetRegion.locale;
        }
    }

    if (shouldRedirect) {
        // To reduce the memory used by clientRegionTimeouts, only store clientRegionTimeouts if we have to redirect to target locale.
        clientRegionTimeouts[detectedIP] = nextRegionTimeout;

        const queryKeys = Object.keys(query);
        const queryString = queryKeys.length ? `?${queryKeys.map((key) => key + '=' + query[key]).join('&')}` : '';
        const protocol =
            req.headers['x-forwarded-proto'] ||
            (req.connection && req.connection.encrypted) ||
            (req.headers.referer && req.headers.referer.split('://')[0]) ||
            'http';
        const redirectToUrl = `${protocol}://${req.headers.host}/${targetLocale}${pathname}${queryString}`;

        data.redirectToUrl = redirectToUrl;

        try {
            res.writeHead(301, {
                Location: redirectToUrl,
                /*
                 * Prevent browser from caching the redirect response. Cached redirect response causes skipped
                 * timeout checking logic, thus the same URL will always be redirected.
                 */
                'Cache-Control': 'no-cache',
            });
            res.end();
            // eslint-disable-next-line consistent-return
            return;
        } catch (e) {
            console.log(e);
        }
    }

    // eslint-disable-next-line consistent-return
    return {
        ...appProps,
        regionTimeout: nextRegionTimeout,
        ip: detectedIP,
    };
};

export default appWithTranslation(AltiveApp);

import { i18n } from 'next-i18next';
import Language from 'src/config/Language';

const getCurrentLocale = () => (i18n && i18n.language) || 'en';

const getLocaleForServer = () => Language[getCurrentLocale()].locale;

const getDefaultPhoneCountryCode = () => Language[getCurrentLocale()].phoneCountryCode;

export default { getCurrentLocale, getLocaleForServer, getDefaultPhoneCountryCode };

const GaCategory = {
    marketing: 'Marketing',
    marketingQ421: 'Marketing_Q421',
    register: 'Register',
    login: 'Login',
    forgotPassword: 'Forgot Password',
    platform: 'Platform',
};

export default GaCategory;

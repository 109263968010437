import React, { useCallback } from 'react';
import NextLink from 'next/link';
import useRouter from 'src/shared/hooks/useRouter';

import common from 'src/shared/utils/common';

import type { LinkProps as NextLinkProps } from 'next/link';
import mobileLinkUtils from 'src/shared/utils/mobileLinkUtils';
import PostMessageType from 'src/shared/config/PostMessageType';

// @TODO fix href type when have time
export type LinkProps = NextLinkProps & { href: any; query?: any; replace?: boolean };

export const Link: React.FC<LinkProps> = (props) => {
    const router = useRouter();

    const hrefPathname = props.href.pathname || props.href;
    const query = props.href.query || props.query || {};

    const route = common.getAsPath(hrefPathname, query);
    const as = props.as ? props.as : route.as;
    const isShallow = router.pathname === route.path.pathname || props.shallow;

    const postMessage = useCallback(() => {
        mobileLinkUtils.postMessage(
            props.replace ? PostMessageType.replace : PostMessageType.push,
            mobileLinkUtils.getRoutePathKey(props.href),
            props.query
        );
    }, [props.href, props.query, props.replace]);

    if (router.isMobile) {
        /*
         * Implant the post message handler to the first children
         * Reference: Implementation of <Link /> in nextjs;
         */
        const child: any = React.Children.only(props.children);
        const childProps = {
            onClick: (e: React.MouseEvent) => {
                if (child.props && typeof child.props.onClick === 'function') {
                    child.props.onClick(e);
                }
                if (!e.defaultPrevented) {
                    postMessage();
                }
            },
        };

        return React.cloneElement(child, childProps);
    }

    return (
        <NextLink {...props} shallow={isShallow} as={as}>
            {props.children}
        </NextLink>
    );
};
Link.displayName = 'Link';

Link.defaultProps = {
    shallow: false,
    query: {},
    replace: false,
};

export default Link;

import ReactGA from 'react-ga';

const GA_ID = process.env.GA_ID || false;

const initialize = () => {
    ReactGA.initialize(GA_ID, {
        debug: process.env.DEV || process.env.NODE_ENV === 'development',
    });
};

const pageView = (path) => {
    ReactGA.pageview(path);
};

const callEvent = (category, action, label) => {
    ReactGA.event({
        category,
        action,
        label,
    });
};

/*
 * @returns {Promise} Promise object represents the GA information got from the current tracker
 */
const getGaInfo = () =>
    new Promise((resolve) =>
        ReactGA.ga((tracker) =>
            resolve({
                clientId: tracker.get('clientId'),
                referrer: tracker.get('referrer'),
            })
        )
    );

export default {
    initialize,
    getGaInfo,
    pageView,
    callEvent,
};

const HOME = '/';
const REGISTER = '/register';
const LOGIN = '/login';
const HOW_IT_WORKS = '/how-it-works';
const ALTIVE_PLATFORM = '/how-it-works/altive-platform';
const OFFERINGS = '/how-it-works/offerings';
const WHY_ALTIVE = '/about-altive/why-altive';
const OUR_TEAM = '/who-we-are/our-team';
const PRIVATE_MARKET = '/private-market';
const DISCLAIMER = '/disclaimer';
const FORGOT_PASSWORD = '/forgot-password';
const TERMS_AND_CONDITIONS = '/terms-and-conditions';
const PRIVACY_POLICY = '/privacy-policy';
const PROFESSIONAL_INVESTOR_NOTICE = '/professional-investor-notice';
const ACTIVATION = '/activation';
const SIGN = '/sign';
const NON_DISCLOSURE_AGREEMENT = '/non-disclosure-agreement';
const IMPORTANT_RISK_DISCLOSURE = '/important-risk-disclosure';
const CONTACT_US = '/contact-us';

const MEDIA = '/media';
const NEWS = '/media/news';
const INSIGHTS = '/media/insights';
const EVENTS = '/media/events';
const NEWS_LIST = '/media/[category]';
const NEWS_DETAIL = '/media/[category]/[articleUrl]';

const PORTFOLIO = '/dashboard/portfolio/[userId]';
const PORTFOLIO_KYC = '/dashboard/portfolio/[userId]/kyc';
const KYC_DETAIL = '/dashboard/portfolio/[userId]/kyc/[id]';
const CONTRACT_LIST = '/dashboard/portfolio/[userId]/[allocationId]/contract';
const CONTRACT_DETAIL = '/dashboard/portfolio/[userId]/[allocationId]/contract/[id]';
const DOCUMENT_HUB_LIST = '/dashboard/portfolio/[userId]/document';
const INVESTMENT_REPORTS = '/dashboard/portfolio/[userId]/document/[investmentId]/statements-and-reports';
const INVESTMENT_NOTICES = '/dashboard/portfolio/[userId]/document/[investmentId]/advices-and-notices';
const REVIEW_ALLOCATION = '/dashboard/portfolio/[userId]/[allocationId]/review';
const ALLOCATION_PAYMENT = '/dashboard/portfolio/[userId]/[allocationId]/payment'; // @updated
const PORTFOLIO_SETTING = '/dashboard/portfolio/[userId]/setting';
const ASSESSMENT = '/dashboard/portfolio/[userId]/assessment';
const ADD_INVESTOR = '/dashboard/portfolio/[userId]/investor';
const FUND_CLIENT_LIST = '/dashboard/portfolio/[userId]/fund'; // @TODO rename fund
const FUND_CLIENT_LIST_DETAIL = '/dashboard/portfolio/[userId]/fund/[id]'; // @TODO rename fund
const INVESTMENT = '/dashboard/investment';
const INVESTMENT_DETAIL = '/dashboard/investment/[id]';
const INVESTMENT_ALLOCATION = '/dashboard/investment/[id]/allocation';
const CONTACT = '/dashboard/contact';

export default {
    HOME,
    REGISTER,
    LOGIN,
    HOW_IT_WORKS,
    ALTIVE_PLATFORM,
    OFFERINGS,
    WHY_ALTIVE,
    OUR_TEAM,
    PRIVATE_MARKET,
    DISCLAIMER,
    FORGOT_PASSWORD,
    TERMS_AND_CONDITIONS,
    PRIVACY_POLICY,
    INVESTMENT,
    PORTFOLIO,
    PORTFOLIO_KYC,
    KYC_DETAIL,
    PORTFOLIO_SETTING,
    INVESTMENT_DETAIL,
    CONTRACT_LIST,
    CONTRACT_DETAIL,
    DOCUMENT_HUB_LIST,
    INVESTMENT_REPORTS,
    INVESTMENT_NOTICES,
    INVESTMENT_ALLOCATION,
    REVIEW_ALLOCATION,
    CONTACT,
    CONTACT_US,
    PROFESSIONAL_INVESTOR_NOTICE,
    ACTIVATION,
    FUND_CLIENT_LIST,
    FUND_CLIENT_LIST_DETAIL,
    NON_DISCLOSURE_AGREEMENT,
    IMPORTANT_RISK_DISCLOSURE,
    SIGN,
    ASSESSMENT,
    ADD_INVESTOR,
    ALLOCATION_PAYMENT,
    MEDIA,
    NEWS,
    INSIGHTS,
    EVENTS,
    NEWS_LIST,
    NEWS_DETAIL,
};

import React, { useEffect, useRef } from 'react';
import styles from './styles.module.scss';
import { animated, useTransition, config } from 'react-spring';
import PlainButton from 'src/shared/components/PlainButton';
import ModalBackground from 'src/shared/components/ModalBackground';

interface Props {
    className?: string;
    width?: string | number;
    height?: string | number;
    visible?: boolean;
    hideClose?: boolean;
    enableFreezing?: boolean;
    onClose?: () => void;
}

const DialogModal: React.FC<Props> = (props) => {
    const pageYOffset = useRef<number>(0);

    const transitions = useTransition(props.visible, {
        from: { opacity: 0, transform: 'translate3d(0, -32px, 0px)' },
        enter: { opacity: 1, transform: 'translate3d(0, 0px, 0px)' },
        leave: { opacity: 0, transform: 'translate3d(0, -32px, 0px)' },
        config: config.stiff,
    });

    useEffect(() => {
        if (props.enableFreezing) {
            if (props.visible) {
                pageYOffset.current = window.pageYOffset || 0;
                document.body.style.top = `-${pageYOffset}px`;
                document.body.classList.add('fixed');
            } else {
                document.body.style.top = '0px';
                document.body.classList.remove('fixed');
                window.scrollTo(0, pageYOffset.current);
            }
        }
        return () => {
            if (props.enableFreezing) {
                document.body.style.top = '0px';
                document.body.classList.remove('fixed');
                window.scrollTo(0, pageYOffset.current);
            }
        };
    }, [props.visible, props.enableFreezing]);

    return (
        <ModalBackground visible={props.visible} animationSpeed="stiff">
            {transitions(
                (trans, item) =>
                    item && (
                        <animated.div
                            className={`${styles.popup} ${props.className}`}
                            style={{ transform: trans.transform, width: props.width, height: props.height }}
                        >
                            {!props.hideClose && props.onClose && (
                                <div className={styles.close}>
                                    <PlainButton onClick={props.onClose}>
                                        <img src="/static/images/svg/Close@3x.svg" alt="close icon" />
                                    </PlainButton>
                                </div>
                            )}
                            <div className={styles.content}>{props.children}</div>
                        </animated.div>
                    )
            )}
        </ModalBackground>
    );
};

DialogModal.defaultProps = {
    className: '',
    width: '400px',
    height: 'auto',
    enableFreezing: false,
    hideClose: false,
    visible: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onClose: () => {},
};

export default DialogModal;

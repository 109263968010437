import React from 'react';
import styles from './styles.module.scss';
import { animated, useTransition, config } from 'react-spring';
import classNames from 'classnames';

interface Props {
    className?: string;
    visible?: boolean;
    animationSpeed?: 'default' | 'stiff';
    backgroundColor?: 'default' | 'dark';
}

const ModalBackground: React.FC<Props> = (props) => {
    const transitions = useTransition(props.visible, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: config[props.animationSpeed],
    });

    const containerClassNames = classNames(styles.container, styles[`background-${props.backgroundColor}`], props.className);

    return (
        <>
            {transitions(
                (trans, item) =>
                    item && (
                        <animated.div className={containerClassNames} style={{ opacity: trans.opacity }}>
                            {props.children}
                        </animated.div>
                    )
            )}
        </>
    );
};

ModalBackground.defaultProps = {
    className: '',
    visible: false,
    animationSpeed: 'default',
    backgroundColor: 'default',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
};

export default ModalBackground;

import RoutePaths from 'src/config/RoutePaths';

const baseUrl = process.env.BASE_URL;

const getRoutePathKey = (url) => {
    const index = Object.values(RoutePaths).findIndex((x) => x === url);
    if (index !== -1) {
        const key = Object.keys(RoutePaths)[index];
        return key;
    }
    return '';
};

const postMessage = (type, key = '', params = {}) => {
    const w = window as any;
    if (w.ReactNativeWebview) {
        w.ReactNativeWebview.postMessage(
            JSON.stringify({
                type,
                key,
                params,
            })
        );
    } else if (w.ReactNativeWebView) {
        w.ReactNativeWebView.postMessage(
            JSON.stringify({
                type,
                key,
                params,
            })
        );
    } else if (window.postMessage) {
        window.postMessage(
            JSON.stringify({
                type,
                key,
                params,
            }),
            baseUrl
        );
    } else {
        console.log("postMessage() function doesn't exist: ", { type, key, params });
    }
};

const mobileLinkUtils = {
    getRoutePathKey,
    postMessage,
};

export default mobileLinkUtils;

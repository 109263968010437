const APPROVED = 'approved';
const CONDITIONAL_APPROVED = 'conditional_approved';
const CONDITIONAL_APPROVAL_EXPIRED = 'conditional_approval_expired';
const CHANGES_REQUIRED = 'changes_required';
const REVIEWING = 'reviewing';
const REJECTED = 'rejected';
const DRAFT = 'draft';
const ADMIN_DRAFT = 'admin_draft';

const KYCStatus = {
    APPROVED,
    CONDITIONAL_APPROVED,
    CONDITIONAL_APPROVAL_EXPIRED,
    CHANGES_REQUIRED,
    REVIEWING,
    REJECTED,
    DRAFT,
    ADMIN_DRAFT,
};

export default KYCStatus;

import { createSlice } from '@reduxjs/toolkit';
import initialState from './state';
import { persistReducer } from 'redux-persist';
import { CookieStorage } from 'redux-persist-cookie-storage';
import Cookies from 'cookies-js';

const slice = createSlice({
    name: 'preference',
    initialState,
    reducers: {
        setPreference: (state, action) => {
            state = { ...state, ...action.payload };
        },
        clearPreference: (state) => {
            state = { confirmDisclaimer: false, subscribeNewsletter: false, sign: {} };
        },
        setConfirmDisclaimer: (state, action) => {
            state.confirmDisclaimer = action.payload;
        },
    },
});

const { actions: preferenceActions, reducer } = slice;

const persistConfig = {
    key: 'preference',
    storage: new CookieStorage(Cookies, {
        expiration: {
            default: 365 * 86400,
        },
    }),
};

const preferenceReducer = persistReducer(persistConfig, reducer);

export { preferenceReducer, preferenceActions };

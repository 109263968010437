import React from 'react';
import useRouter from 'src/shared/hooks/useRouter';

const withRouter = (WrappedComponent: React.ComponentType<any>) => {
    const Component = (props) => {
        const router = useRouter();

        return <WrappedComponent router={router} {...props} />;
    };

    return Component;
};

export default withRouter;

const GaAction = {
    clickAddress: 'Click Address',
    clickHighlightArticle: 'Click highlight article',
    clickCloseButtonOnSubscriptionPopup: 'Click Close Button on Subscription Popup',
    clickConfirmOneTimePasscodeButton: 'Click Confirm One Time Passcode Button',
    clickContactSalesButton: 'Click Contact Sales Button',
    contactSalesSuccess: 'Contact Sales Success',
    contactSalesFailed: 'Contact Sales Failed',
    clickContactUs: 'Click Contact Us',
    clickContactUsButton: 'Click Contact Us Button',
    clickContactSpecialistButton: 'Click Contact Specialist Button',
    clickCreateAccountButton: 'Click Create Account Button',
    clickCreateAccountButtonForAdvisor: 'Click Create Account Button - Advisor',
    clickCreateAccountButtonForInvestor: 'Click Create Account Button - Investor',
    clickDisclaimerAgreeButton: 'Click Disclaimer Agree Button',
    clickEmail: 'Click Email',
    clickEvent: 'Click Event',
    clickWhyAltive: 'Click Why Altive',
    clickOurTeam: 'Click Our Team',
    clickMedia: 'Click Media',
    clickInsightsArticle: 'Click Insights Article',
    clickNewsArticle: 'Click Media Article',
    clickEventsArticle: 'Click Events Article',
    clickInsightsShowMore: 'Click Insights Show More',
    clickNewsShowMore: 'Click Media Article Show More',
    clickEventsShowMore: 'Click Events Article Show More',
    clickForgotPasswordButton: 'Click Forgot Password Button',
    clickGetStartedButton: 'Click Get Started Button',
    clickLogoHome: 'Click Logo Home',
    clickHowItWorks: 'Click How It Works',
    clickWhyPrivateMarket: 'Click Why Private Market',
    clickOurAdvantages: 'Click Our Advantages',
    clickInsights: 'Click See Market Insights',
    clickLeaderLinkedIn: 'click Leader LinkedIn Link',
    clickLearnMore: 'Click Learn More',
    clickLinkedIn: 'click LinkedIn',
    clickLogin: 'Click Login',
    clickPI: 'Click PI',
    clickTeamThumbnail: 'Click Team Thumbnail',
    clickLoginButton: 'Click Login Button',
    clickLogoutButton: 'click Logout Button',
    clickPhone: 'Click Phone',
    clickPrivacyPolicyButton: 'Click Privacy Policy Button',
    clickReceiveUpdateButton: 'Click Receive Update Button',
    clickSignUp: 'Click Sign Up',
    clickRegisterButton: 'Click Register Button',
    clickRequestAllocationButton: 'Click Request Allocation Button',
    clickSendOneTimePasscodeButton: 'Click Send One Time Passcode Button',
    clickSubscribeButtonOnSubscriptionPopup: 'Click Subscribe Button on Subscription Popup',
    clickSwitchLanguage: 'Switch Language',
    clickTermsConditionsButton: 'Click Terms & Conditions Button',
    clickViewDetailButton: 'Click View Detail Button',
    clickViewStrategiesButton: 'Click View Strategies Button',
    clickWeChat: 'click We Chat',
    clickWhatsApp: 'click WhatsApp',
    clickWhoWeAreButton: 'Click Who We Are Button',
    contactUsFailed: 'Contact Failed',
    contactUsSuccess: 'Contact Success',
    forgotPasswordFailed: 'Forgot Password Failed',
    forgotPasswordSuccess: 'Forgot Password Success',
    loginFailed: 'Login Failed',
    loginSuccess: 'Login Success',
    registerFailed: 'Register Failed',
    registerSuccess: 'Register Success',
    showSubscriptionPopup: 'Show Subscription Popup',
    clickViewPlatformFeatures: 'Click View Platform Features',
    clickFaq: 'Click FAQ',
    clickSeeMarketInsights: 'Click See Market Insights',
    clickPrivateMarketProducts: 'Click Private Market Products',
    disclaimerIsShown: 'Display Disclaimer',
    clickFeatures: 'Click Features',
    clickOfferings: 'Click Offerings',
    stayed30s: 'Viewed 30sec',
};

export default GaAction;

import produce from 'immer';
import constants from 'src/shared/store/user/constants';
import initialState from 'src/shared/store/user/state';
import Cookies from 'cookies-js';
import { persistReducer } from 'redux-persist';
import { CookieStorage } from 'redux-persist-cookie-storage';

// save locale value in cookie
const persistConfig = {
    key: 'user',
    storage: new CookieStorage(Cookies, {
        expiration: {
            default: 365 * 86400,
        },
    }),
};

const tempPersistConfig = {
    key: 'temp_user',
    storage: new CookieStorage(Cookies, {
        expiration: {
            default: 0,
        },
    }),
};

const userReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case constants.SET_USER_INFO:
                draft.memberInfo = { ...draft.memberInfo, ...action.payload, isLoggedIn: true };
                break;
            case constants.CLEAR_USER_INFO:
                draft.memberInfo = { isLoggedIn: false, ...action.payload };
                break;
            case constants.FETCHING_USER_INFO:
                draft.memberInfo = { ...draft.memberInfo, ...action.payload };
                break;
            case constants.UPDATE_USER_EXPIRE_DATE:
                draft.memberInfo = { ...draft.memberInfo, expireDate: action.payload };
                break;
            default:
                break;
        }
    });

const tempUserReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case constants.SET_TEMP_USER_INFO:
                draft.memberInfo = { ...draft.memberInfo, ...action.payload, isLoggedIn: true };
                break;
            case constants.CLEAR_TEMP_USER_INFO:
                draft.memberInfo = { isLoggedIn: false, ...action.payload };
                break;
            case constants.UPDATE_TEMP_USER_EXPIRE_DATE:
                draft.memberInfo = { ...draft.memberInfo, expireDate: action.payload };
                break;
            default:
                break;
        }
    });

export default {
    user: persistReducer(persistConfig, userReducer),
    tempUser: persistReducer(tempPersistConfig, tempUserReducer),
};

import GaAction from 'src/config/GaAction';
import GaCategory from 'src/config/GaCategory';
import GaService from 'src/services/GaService';

export const fireGA = (category: keyof typeof GaCategory, action: keyof typeof GaAction, label = '') => {
    GaService.callEvent(GaCategory[category], GaAction[action], label);
};

export const getGaInfo = () => GaService.getGaInfo();

export default {
    fireGA,
    getGaInfo,
};

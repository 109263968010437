import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styles from './styles.module.scss';
import useRouter from 'src/shared/hooks/useRouter';

const Image = (props) => {
    const { baseLang, multiLang, className, animation, src, alt, renderEmptyImg, dataTest, ...otherProps } = props;
    const { router } = useRouter();
    const [hasError, setHasError] = useState(false);

    let animationProps = {};
    switch (animation) {
        case 'fade':
            animationProps = {
                'data-aos': 'fade',
                'data-aos-duration': 500,
                'data-aos-once': true,
            };
            break;
        default:
            break;
    }

    if (!src && renderEmptyImg) return renderEmptyImg();

    if (!src) return <div className={`${styles.image} ${className}`} />;

    if (hasError && renderEmptyImg) return renderEmptyImg();

    let imageSrc = src;
    if (multiLang && router && router.locale) {
        if (props[`src-${router.locale}`]) {
            imageSrc = props[`src-${router.locale}`];
        } else if (router.locale !== baseLang) {
            imageSrc = imageSrc.replace(/(\.[\w\d_-]+)$/i, `_${router.locale}$1`);
        }
    }

    return (
        <img
            src={imageSrc}
            className={`${styles.image} ${className}`}
            alt={alt}
            data-test={dataTest}
            {...animationProps}
            {...otherProps}
            onError={() => setHasError(true)}
        />
    );
};

export default Image;

Image.defaultProps = {
    animation: '',
    className: null,
    baseLang: 'en',
    multiLang: false,
    src: '',
    alt: '',
    width: '',
    height: '',
    renderEmptyImg: null,
    dataTest: '',
};

Image.propTypes = {
    animation: PropTypes.string,
    className: PropTypes.string,
    baseLang: PropTypes.string,
    multiLang: PropTypes.bool,
    src: PropTypes.string,
    alt: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    renderEmptyImg: PropTypes.func,
    dataTest: PropTypes.string,
};

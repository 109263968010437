import { AlertDialogType, ConfirmDialogType, CustomDialogType, ErrorDialogType, ExpireDialogType } from './types';

interface ModalType {
    show: boolean;
}

interface disclaimerModalType {
    visible: boolean;
}

const initialState: {
    loading: boolean;
    isFirstLoad: boolean;
    confirmDialog: ConfirmDialogType;
    alertDialog: AlertDialogType;
    errorDialog: ErrorDialogType;
    expireDialog: ExpireDialogType;
    customDialog: CustomDialogType;
    modal: ModalType;
    disclaimerModal: disclaimerModalType;
} = {
    loading: false,
    isFirstLoad: true,
    confirmDialog: {
        text: '',
        confirmText: '',
        cancelDisabled: false,
        showDialog: false,
        shouldRemainAfterConfirm: false,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onCancel: () => {},
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onConfirm: () => {},
    },
    alertDialog: {
        text: '',
        title: '',
        showDialog: false,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onConfirm: () => {},
    },
    errorDialog: {
        code: '',
        title: '',
        message: '',
        buttons: [],
        showDialog: false,
    },
    expireDialog: {
        timeout: 0,
        showDialog: false,
    },
    customDialog: {
        children: null,
        className: '',
        style: {},
        width: '100%',
        height: 'auto',
        showDialog: false,
    },
    modal: {
        show: false,
    },
    disclaimerModal: {
        visible: false,
    },
};

export default initialState;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import initialState from './state';
import {
    AlertDialogDTO,
    AlertDialogType,
    ConfirmDialogDTO,
    ConfirmDialogType,
    CustomDialogDTO,
    CustomDialogType,
    ErrorDialogDTO,
    ErrorDialogType,
    ExpireDialogDTO,
    ExpireDialogType,
} from './types';

const slice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        setIsFirstLoad: (state, action) => {
            state.isFirstLoad = action.payload;
        },
        setConfirmDialog: (state, action: PayloadAction<ConfirmDialogType>) => {
            state.confirmDialog = { ...initialState.confirmDialog, ...action.payload };
        },
        setAlertDialog: (state, action: PayloadAction<AlertDialogType>) => {
            state.alertDialog = { ...initialState.alertDialog, ...action.payload };
        },
        setErrorDialog: (state, action: PayloadAction<ErrorDialogType>) => {
            state.errorDialog = { ...initialState.errorDialog, ...action.payload };
        },
        setExpireDialog: (state, action: PayloadAction<ExpireDialogType>) => {
            state.expireDialog = { ...initialState.expireDialog, ...action.payload };
        },
        setCustomDialog: (state, action: PayloadAction<CustomDialogType>) => {
            state.customDialog = { ...initialState.customDialog, ...action.payload };
        },
        setModal: (state, action) => {
            state.modal = { ...initialState.modal, ...action.payload };
        },
        showDisclaimerModal: (state) => {
            state.disclaimerModal = { ...initialState.disclaimerModal, visible: true };
        },
        dismissDisclaimerModal: (state) => {
            // disclaimerModal state will be reset in showDisclaimerModal
            state.disclaimerModal = { ...state.disclaimerModal, visible: false };
        },
    },
});

const globalReducer = slice.reducer;

const { actions } = slice;

const setIsFirstLoad = (isFirstLoad) => {
    return actions.setIsFirstLoad(isFirstLoad);
};

const showConfirmDialog = (params: ConfirmDialogDTO) => {
    return actions.setConfirmDialog({
        ...params,
        showDialog: true,
    });
};

const dismissConfirmDialog = () => {
    return actions.setConfirmDialog({
        ...initialState.confirmDialog,
    });
};

const showAlertDialog = (params: AlertDialogDTO) => {
    return actions.setAlertDialog({
        ...params,
        showDialog: true,
    });
};

const dismissAlertDialog = () => {
    return actions.setAlertDialog({
        ...initialState.alertDialog,
    });
};

const showErrorDialog = (params: ErrorDialogDTO) => {
    return actions.setErrorDialog({
        ...params,
        showDialog: true,
    });
};

const dismissErrorDialog = () => {
    return actions.setErrorDialog({
        ...initialState.errorDialog,
    });
};

const handleErrorDialog = (params: ErrorDialogType) => {
    if (params.showDialog) {
        return actions.setErrorDialog({
            ...params,
            showDialog: true,
        });
    }

    return {};
};

const showExpireDialog = (params: ExpireDialogDTO) => {
    return actions.setExpireDialog({
        ...params,
        showDialog: true,
    });
};

const dismissExpireDialog = () => {
    return actions.setExpireDialog({ ...initialState.expireDialog });
};

const showCustomDialog = (params: CustomDialogDTO) => {
    return actions.setCustomDialog({
        ...params,
        showDialog: true,
    });
};

const dismissCustomDialog = () => {
    return actions.setCustomDialog({
        ...initialState.customDialog,
    });
};

// TODO Replace modal with customDialog
const showModal = (params: any) => {
    return actions.setModal({
        ...params,
        show: true,
    });
};

const dismissModal = () => {
    return actions.setModal({
        ...initialState.modal,
    });
};

const globalActions = {
    setIsFirstLoad,
    showConfirmDialog,
    dismissConfirmDialog,
    showAlertDialog,
    dismissAlertDialog,
    showErrorDialog,
    dismissErrorDialog,
    handleErrorDialog,
    showExpireDialog,
    dismissExpireDialog,
    showCustomDialog,
    dismissCustomDialog,
    showModal,
    dismissModal,
    showDisclaimerModal: actions.showDisclaimerModal,
    dismissDisclaimerModal: actions.dismissDisclaimerModal,
};

export { globalReducer, globalActions };

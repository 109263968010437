import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import logger from './middleware/logger';

import { globalReducer } from 'src/shared/store/global';
import userReducers from 'src/shared/store/user/reducer';
import { preferenceReducer } from './preference';
import { persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

const isClient = typeof window !== 'undefined';

const rootReducer = combineReducers({
    global: globalReducer,
    ...userReducers,
    preference: preferenceReducer,
});

const store = configureStore({
    reducer: rootReducer,
    middleware: [thunk, logger],
    devTools: process.env.NODE_ENV !== 'production' && typeof window === 'object',
});

const persistor = isClient ? persistStore(store) : {};

export { store, persistor };

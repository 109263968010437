import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles.module.scss';
import { compose } from 'redux';
import withConnect from 'src/shared/utils/withConnect';
import globalActions from 'src/shared/store/global/actions';
import PlainButton from 'src/shared/components/PlainButton';
import classnames from 'classnames';

class Modal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            pageYOffset: 0,
        };
    }

    componentDidMount() {
        this.setState({ showModal: this.props.show });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.show !== this.props.show) {
            const pageYOffset = window.pageYOffset || 0;
            this.setState({ showModal: this.props.show, pageYOffset });
            if (this.props.show) {
                document.body.style.top = `-${pageYOffset}px`;
                document.body.classList.add('fixed');
            } else {
                document.body.style.top = '0px';
                document.body.classList.remove('fixed');
            }
        }
    }

    show = () => {
        const pageYOffset = window.pageYOffset || 0;
        this.setState({ showModal: true, pageYOffset });
        document.body.style.top = `-${pageYOffset}px`;
        document.body.classList.add('fixed');
    };

    onClose = () => {
        const { pageYOffset } = this.state;
        const { dispatch, onClose } = this.props;
        dispatch(globalActions.dismissModal());
        this.setState({ showModal: false });
        document.body.style.top = '0px';
        document.body.classList.remove('fixed');
        window.scrollTo(0, pageYOffset);
        if (onClose) {
            onClose();
        }
        return true;
    };

    render() {
        const { showModal } = this.state;
        const { children, className, width, hideClose, height, style } = this.props;
        const heightStyle = height
            ? {
                  maxHeight: height,
                  minHeight: height,
                  height,
              }
            : {};

        return (
            <div className={`${styles.container} ${showModal ? styles.show : styles.hide}`}>
                <div className={classnames(styles.contentContainer, className)} style={{ width, ...heightStyle, ...style }}>
                    {!hideClose && (
                        <div className={styles.close}>
                            <PlainButton onClick={this.onClose}>
                                <img src="/static/images/svg/Close@3x.svg" alt="close icon" />
                            </PlainButton>
                        </div>
                    )}
                    {children}
                </div>
            </div>
        );
    }
}

Modal.defaultProps = {
    className: '',
    width: '1024px',
    height: null,
    children: null,
    show: false,
    hideClose: false,
    onClose: () => {},
    style: {},
};

Modal.propTypes = {
    className: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    children: PropTypes.node,
    show: PropTypes.bool,
    hideClose: PropTypes.bool,
    onClose: PropTypes.func,
    style: PropTypes.object,
};

const mapStateToProps = (state) => ({ ...state.global.modal });

export default compose(withConnect(mapStateToProps))(Modal);

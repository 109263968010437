import { useRouter as useNextRouter, NextRouter } from 'next/router';
import { useCallback } from 'react';
import PostMessageType from 'src/shared/config/PostMessageType';
import common from 'src/shared/utils/common';
import mobileLinkUtils from 'src/shared/utils/mobileLinkUtils';

interface TransitionOptions {
    shallow?: boolean;
    locale?: string | false;
    scroll?: boolean;
}

interface CustomNextRouter extends NextRouter {
    isMobile: boolean;
    pushPath: (path: string, params?: any, options?: TransitionOptions) => Promise<boolean>;
    replacePath: (path: string, params?: any, options?: TransitionOptions) => Promise<boolean>;
}

const useRouter = (): CustomNextRouter => {
    const router = useNextRouter();

    const { mode } = router.query;

    const isMobile = mode === 'mobile';

    const pushPath = useCallback(
        (path: string, params?: any, options?: TransitionOptions) => {
            if (isMobile) {
                mobileLinkUtils.postMessage(PostMessageType.push, mobileLinkUtils.getRoutePathKey(path), params);
                return new Promise<boolean>((resolve) => resolve(true));
            }
            const route = common.getAsPath(path, params);
            return router.push(route.path, route.as, options);
        },
        [router, isMobile]
    );

    const replacePath = useCallback(
        (path: string, params: any, options?: TransitionOptions) => {
            if (isMobile) {
                mobileLinkUtils.postMessage(PostMessageType.replace, mobileLinkUtils.getRoutePathKey(path), params);
                return new Promise<boolean>((resolve) => resolve(true));
            }
            const route = common.getAsPath(path, params);
            return router.replace(route.path, route.as, options);
        },
        [router, isMobile]
    );

    const back = () => {
        if (isMobile) {
            mobileLinkUtils.postMessage(PostMessageType.back);
            return new Promise<boolean>((resolve) => resolve(true));
        }
        return router.back();
    };

    return { ...router, pushPath, replacePath, back, isMobile };
};

export default useRouter;

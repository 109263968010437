import React from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';

interface Props {
    tag?: 'p' | 'label' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    textAlign?: '' | 'left' | 'center' | 'right' | 'justify';
    /**
     * What font size to use
     */
    fontSize?: string;
    className?: string;
    /**
     * It can be string or a element
     */
    children?: React.ReactNode;
    /**
     * It accept html content
     */
    html?: string;
    dataTest?: string;
    color?: 'dark' | 'white';
}

/**
 * A component to render the base text element, you can change fontsize, color and which tag to use (p, h1, h6)
 */
const BaseText = (props: Props) => {
    const Tag = props.tag;
    const textClass = `${styles.text} ${styles[props.color]} ${props.className}`;
    const style: any = {};
    if (props.fontSize) {
        style.fontSize = props.fontSize;
    }
    if (props.textAlign) {
        style.textAlign = props.textAlign;
    }
    if (props.html) {
        return <Tag data-test={props.dataTest} className={textClass} style={style} dangerouslySetInnerHTML={{ __html: props.html }} />;
    }
    return (
        <Tag data-test={props.dataTest} className={textClass} style={style}>
            {props.children}
        </Tag>
    );
};

// BaseText.propTypes = {
//     tag: PropTypes.oneOf(['p', 'label', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
//     textAlign: PropTypes.oneOf(['', 'left', 'center', 'right', 'justify']),
//     /**
//      * What font size to use
//      */
//     fontSize: PropTypes.string,
//     className: PropTypes.string,
//     /**
//      * It can be string or a element
//      */
//     children: PropTypes.any,
//     /**
//      * It accept html content
//      */
//     html: PropTypes.string,
//     dataTest: PropTypes.string,
// };

BaseText.defaultProps = {
    className: '',
    tag: 'p',
    children: '',
    html: '',
    textAlign: '',
    fontSize: '',
    dataTest: '',
    color: 'dark',
};

export default BaseText;

const Language = {
    en: {
        locale: 'en',
        path: 'en',
        label: 'EN',
        phoneCountryCode: '852',
        mediaLocale: 'eng',
    },
    // au: {
    //     locale: 'en-AU',
    //     path: 'au',
    //     label: 'EN-AU',
    //     phoneCountryCode: '61',
    //     metaLang: 'en',
    //     mediaLocale: 'eng',
    // },
    tc: {
        locale: 'zh-TW',
        path: 'tc',
        label: '繁',
        phoneCountryCode: '852',
        mediaLocale: 'zh-cht',
    },
    sc: {
        locale: 'zh-CN',
        path: 'sc',
        label: '简',
        phoneCountryCode: '852',
        mediaLocale: 'zh-chs',
    },
    catchAll: {
        locale: 'en',
        path: 'catchAll',
        label: 'ENG',
        phoneCountryCode: '852',
        mediaLocale: 'eng',
        hide: true,
    },
};

module.exports = Language;

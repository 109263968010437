import React, { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useDispatch, useSelector } from 'react-redux';
import DialogModal from 'src/shared/components/DialogModal';
import RoundedButton from 'src/shared/components/RoundedButton';
import styles from './styles.module.scss';
import globalActions from 'src/shared/store/global/actions';
import { RootState } from 'src/store/rootReducer';

interface Props {}

const FIXED_BUTTON_WIDTH = '100px';

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
const ConfirmDialog = (props: Props) => {
    const dialog = useSelector((state: RootState) => state.global.confirmDialog);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [visible, setVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const onClose = () => {
        setVisible(false);
        setTimeout(() => dispatch(globalActions.dismissConfirmDialog()), 500);
    };

    const onConfirm = async () => {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        const confirm = dialog.onConfirm || function () {};

        setIsLoading(true);
        await confirm();

        if (!dialog.shouldRemainAfterConfirm) {
            await dispatch(globalActions.dismissConfirmDialog());
        }
        setIsLoading(false);
    };

    useEffect(() => {
        setVisible(dialog.showDialog);
    }, [dialog.showDialog]);

    const cancelButtonWidth = dialog.cancelButtonWidth || FIXED_BUTTON_WIDTH;
    const confirmButtonWidth = dialog.confirmButtonWidth || FIXED_BUTTON_WIDTH;

    // @TODO move render structures to <DialogModal> for all Dialogs

    return (
        <DialogModal visible={visible} onClose={dialog.cancelDisabled ? onConfirm : onClose}>
            <div className={styles.container}>
                <div className={styles.body}>
                    {dialog.title && <div className={styles.title}>{dialog.title}</div>}
                    <div className={styles.content}>{dialog.text}</div>
                </div>
                {!dialog.cancelDisabled && (
                    <div className={styles.button_group}>
                        <RoundedButton width={cancelButtonWidth} type="black" className={styles.cancel} onClick={onClose}>
                            {dialog.cancelText || t('portal_individual_kyc_cancel_button')}
                        </RoundedButton>
                        <RoundedButton loading={isLoading} width={confirmButtonWidth} onClick={onConfirm}>
                            {dialog.confirmText || t('allocation_advisor_step_2_title')}
                        </RoundedButton>
                    </div>
                )}
                {dialog.cancelDisabled && (
                    <div className={styles.button_group_right}>
                        <RoundedButton className={styles.button_confirm} onClick={onConfirm}>
                            {dialog.confirmText || t('allocation_advisor_step_2_title')}
                        </RoundedButton>
                    </div>
                )}
            </div>
        </DialogModal>
    );
};

export default ConfirmDialog;

import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles.module.scss';
import globalActions from 'src/shared/store/global/actions';
import Link from 'src/shared/components/Link';
import { useDispatch } from 'react-redux';

const RoundedButton = ({
    id,
    type,
    minWidth: propMinWidth,
    onClick,
    onConfirm,
    children,
    className,
    width,
    containerStyle,
    innerStyle,
    fade,
    href,
    loading,
    disable,
    supportResponsive,
    isExternalLink,
    buttonType,
}) => {
    const dispatch = useDispatch();

    const onClickConfirm = () => {
        const { message } = onConfirm;
        dispatch(
            globalActions.showConfirmDialog({
                text: message ?? 'Are you sure to take this action?',
                onConfirm: onClick,
            })
        );
    };

    const minWidth = width || propMinWidth;

    // HOTFIX
    // Original logic: buttonClass = disable ? '' : styles[type];
    // don't want to affect too many pages

    const buttonClass = disable ? styles[`${type}-disabled`] : styles[type];
    const fadeProps = fade
        ? {
              'data-aos': 'fade',
              'data-aos-duration': 500,
              'data-aos-once': true,
          }
        : {};

    if (href) {
        return (
            <div className={`${styles.container} ${supportResponsive ? styles.responsive : ''} ${className}`} style={containerStyle} {...fadeProps}>
                <Link href={href}>
                    <a className={styles.link} target={isExternalLink ? '_blank' : '_self'}>
                        <button
                            id={id}
                            type="button"
                            disabled={disable}
                            className={buttonClass}
                            style={{ width, minWidth, ...innerStyle }}
                            onClick={onConfirm ? onClickConfirm : onClick}
                        >
                            {children}
                        </button>
                    </a>
                </Link>
            </div>
        );
    }

    return (
        <div className={`${className} ${styles.container} ${supportResponsive ? styles.responsive : ''}`} style={containerStyle} {...fadeProps}>
            <button
                id={id}
                type={buttonType}
                disabled={disable}
                className={buttonClass}
                style={{ width, minWidth, ...innerStyle }}
                onClick={onConfirm ? onClickConfirm : onClick}
            >
                {loading ? (
                    <div className={styles.ring}>
                        <div />
                        <div />
                        <div />
                        <div />
                    </div>
                ) : (
                    children
                )}
            </button>
        </div>
    );
};

RoundedButton.defaultProps = {
    className: '',
    id: '',
    type: 'brown',
    width: null,
    minWidth: '154px',
    containerStyle: {},
    innerStyle: {},
    onClick: () => {},
    onConfirm: false,
    fade: false,
    href: null,
    loading: null,
    disable: false,
    supportResponsive: false,
    isExternalLink: false,
    buttonType: 'button',
};

RoundedButton.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    type: PropTypes.oneOf([
        'blue',
        'brown',
        'white',
        'black',
        'primary-on-light',
        'secondary-on-light',
        'tertiary-on-light',
        'primary-on-dark',
        'secondary-on-dark',
        'tertiary-on-dark',
        'transparent-on-dark',
        'transparent-on-light',
    ]),
    width: PropTypes.string,
    minWidth: PropTypes.string,
    containerStyle: PropTypes.object,
    innerStyle: PropTypes.object,
    onClick: PropTypes.func,
    onConfirm: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    fade: PropTypes.bool,
    href: PropTypes.string,
    loading: PropTypes.bool,
    disable: PropTypes.bool,
    supportResponsive: PropTypes.bool,
    isExternalLink: PropTypes.bool,
    buttonType: PropTypes.string,
};

export default RoundedButton;

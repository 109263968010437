import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import DialogModal from 'src/shared/components/DialogModal';
import { useDispatch, useSelector } from 'react-redux';
import RoundedButton from 'src/shared/components/RoundedButton';
import Image from 'src/shared/components/Image';
import { useTranslation } from 'next-i18next';
import globalActions from 'src/shared/store/global/actions';
import { RootState } from 'src/store/rootReducer';

const AlertDialog: React.FC = () => {
    const dialog = useSelector((state: RootState) => state.global.alertDialog);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [visible, setVisible] = useState(false);

    const onConfirm = () => {
        if (dialog.onConfirm) {
            dialog.onConfirm();
        }
        setVisible(false);
        setTimeout(() => dispatch(globalActions.dismissAlertDialog()), 500);
    };

    useEffect(() => {
        setVisible(dialog.showDialog);
    }, [dialog.showDialog]);

    const textDisplay = typeof dialog.text === 'string'
        ? <div className={styles.content} dangerouslySetInnerHTML={{ __html: dialog.text }} />
        : <div className={styles.content}>{dialog.text}</div>;

    return (
        <DialogModal visible={visible} onClose={onConfirm}>
            <div className={styles.container}>
                <div className={styles.mainGroup}>
                    {dialog.icon && <Image className={styles.icon} width="22px" height="22px" src={dialog.icon} />}
                    {dialog.isSuccess && <Image className={styles.icon} width="22px" height="22px" src="/static/images/ThankYou(Icon)@2x.png" />}
                    <div className={styles.body}>
                        {dialog.title && (
                            <div className={styles.title} dangerouslySetInnerHTML={{ __html: dialog.title || t('dialog_title_notice') }} />
                        )}
                        {dialog.text && textDisplay}
                    </div>
                </div>
                <div className={styles.buttonGroup}>
                    <RoundedButton minWidth="100px" type="black" onClick={onConfirm}>
                        {dialog.confirmText || t('button_ok')}
                    </RoundedButton>
                </div>
            </div>
        </DialogModal>
    );
};

export default AlertDialog;

import { useEffect, useRef } from 'react';

export default function useInterval(callback, delay) {
    const savedCallback = useRef(callback);
    const interval = useRef(null);

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    // eslint-disable-next-line consistent-return
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null && interval.current === null) {
            interval.current = setInterval(tick, delay);
            return () => {
                clearInterval(interval.current);
                interval.current = null;
            };
        }
        if (!delay && interval.current) {
            clearInterval(interval.current);
            interval.current = null;
        }
    }, [delay]);
}
